<template>
  <div class="taxe">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <div class="taxe-content page-layout">
      <md-card class="meep-form md-card-small">
        <PageHeader
          :title="$t('simulateurs.taxe.title')"
          :has-back="true"
          @back="goBack()"
        />
        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.taxe.emission") }}</label>
                <md-input
                  v-model.number="$v.form.emission.$model"
                  name="emission"
                />
                <span class="md-suffix">{{ $t("sufifix.gpkm") }}</span>

                <template v-if="$v.form.emission.$dirty">
                  <span v-show="!$v.form.emission.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.emission.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.taxe.year") }}</label>
                <md-input v-model.number="$v.form.year.$model" name="year" />

                <template v-if="$v.form.year.$dirty">
                  <span v-show="!$v.form.year.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.year.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <Chooser
              v-model="form.type"
              :items="[
                { label: 'Essence', value: 'essence' },
                { label: 'Diesel', value: 'diesel' },
                { label: 'Hybride', value: 'hybride' },
                { label: 'Électrique', value: 'electrique' },
              ]"
            />

            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" @click="calcu(form)">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>

            <div class="meep-input meep-input-big-result">
              <p class="text">{{ $t("simulateurs.taxe.result") }}</p>
              <p class="result">{{ $$filters.formatNumber(result) }}€</p>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import { required, numeric } from "vuelidate/lib/validators";
import PageHeader from "@/components/PageHeader";
import calculatorsModel from "@/model/calculators";
import Chooser from "@/components/Chooser.vue";
import { isBetween } from "@/services/util";

export default {
  name: "Taxe",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
    Chooser,
  },

  validations: {
    form: {
      emission: { required, numeric },
      year: { required, numeric },
    },
  },

  data() {
    return {
      form: {
        emission: null,
        year: null,
        type: "essence",
      },
      result: 0,
      taxeVehicleData: [],
      taxeCarburrantData: [],
    };
  },

  async mounted() {
    this.taxeVehicleData = await calculatorsModel.getAllEmission();
    this.taxeCarburrantData = await calculatorsModel.getAllCarburant();
  },

  methods: {
    calcu({ emission, type, year }) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const estimation = this.taxeVehicleData.find(item =>
          isBetween(emission, item.emission_min, item.emission_max)
        );
        const vehicule = this.taxeCarburrantData.find(item => {
          return (
            isBetween(year, item.annee_min, item.annee_max) &&
            type === item.type
          );
        });

        this.result = emission * estimation.coef + vehicule.montant;
      }
    },
  },
};
</script>

<style lang="scss">
.taxe {
  .chooser {
    .v-radio {
      flex: 40%;
    }
  }
}
</style>
